const IconShow = ({ fill = 'var(--colour-utility-base)' }) => (
  <svg
    fill={fill}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <path d="M18,24 C18,27.2839999 20.7160001,30 24,30 C27.2820001,30 30,27.2839999 30,24 C30,20.7179999 27.2820001,18 24,18 C20.7160001,18 18,20.7179999 18,24 Z M4.21086146,23.368 L4,24 L4.20887235,24.632 C4.25263602,24.766 8.81599331,38 24,38 C39.1840029,38 43.7473606,24.766 43.789135,24.632 L44,24 L43.7911263,23.368 C43.7473626,23.234 39.1840029,10 24,10 C8.81599331,10 4.25263587,23.234 4.21086146,23.368 Z M8.23313999,24 C9.235726,21.684 13.3614473,14 24,14 C34.644516,14 38.7682498,21.6919999 39.7668571,24 C38.7642711,26.316 34.6385489,34 24,34 C13.3554802,34 9.23174739,26.3080001 8.23313999,24 Z"></path>
  </svg>
);

export default IconShow;