import { useState } from 'react';
import styles from './styles.module.css';

export default function CustomInput({
  label = '',
  type = 'text',
  value = '',
  setValue,
  error = ''
}) {
  const [blurred, setBlurred] = useState(false);

  const handleInput = e => {
    const val = e.target.value;
    setValue(val);
  };

  return (
    <>
      <div className={styles.container}>
        <label
          htmlFor={label}
        >
          { label }
        </label>
        <input
          id={label}
          type={type}
          value={value}
          onChange={handleInput}
          onBlur={() => setBlurred(true)}
          className={`${styles.input} ${(error && blurred) && styles.inputError}`}
        />
      </div>
      { error && blurred ? <span className={styles.errorMessage}>{ error }</span> : null }
    </>
  );
}
