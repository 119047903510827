const IconHide = ({ fill = 'var(--colour-utility-base)' }) => (
  <svg
    fill={fill}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <path d="M14.75,36.045l-7.91,7.886l-2.824,-2.832l37.144,-37.03l2.824,2.832l-7.205,7.184c5.157,3.9 6.984,9.198 7.012,9.283l0.209,0.632l-0.211,0.632c-0.042,0.134 -4.605,13.368 -19.789,13.368c-3.677,-0 -6.731,-0.777 -9.25,-1.955Zm19.193,-19.133l-4.532,4.518c0.377,0.781 0.589,1.654 0.589,2.57c-0,3.284 -2.718,6 -6,6c-0.923,-0 -1.802,-0.215 -2.587,-0.596l-3.604,3.592c1.764,0.621 3.815,1.004 6.191,1.004c10.638,-0 14.764,-7.684 15.766,-10c-0.594,-1.374 -2.296,-4.657 -5.823,-7.088Zm-25.682,14.27c-2.92,-3.263 -4.03,-6.484 -4.052,-6.55l-0.208,-0.632l0.21,-0.632c0.042,-0.134 4.605,-13.368 19.789,-13.368c1.789,0 3.431,0.184 4.935,0.508l-3.538,3.538c-0.452,-0.03 -0.918,-0.046 -1.397,-0.046c-10.638,0 -14.764,7.684 -15.766,10c0.39,0.903 1.259,2.63 2.81,4.399l-2.783,2.783Z"/>
  </svg>
);

export default IconHide;