import { useState, useEffect, useMemo, useRef } from 'react';
import { /*Link, */useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'universal-cookie';
import CustomButton from '@/components/CustomButton/CustomButton';
import CustomInput from '@/components/CustomInput/CustomInput';
import IconShow from '@/components/Icons/IconShow';
import IconHide from '@/components/Icons/IconHide';
// eslint-disable-next-line
// @ts-ignore
import { httpRequest, t } from '@sales-i/utils';
import styles from './styles.module.css';
import RememberMeCheckbox from './RememberMeCheckbox';
import LogoContainerLayout from '../Layout/LogoContainerLayout';
import { createAuth0Client } from '@auth0/auth0-spa-js';

export const emailRegex = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$';

export default function LoginForm() {
  const [genericError, setGenericError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const captchaRef = useRef(null);
  const [recaptcha, setRecaptcha] = useState('');
  const [usingAuth0, setUsingAuth0] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = useMemo(() => new Cookies(null, { path: '/' }), []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (usingAuth0) {
      loginWithAuth0();
    } else {
      loginWithBureau();
    }
  };

  const loginWithAuth0 = async() => {
    const auth0 = await createAuth0Client({
      domain: process.env.AUTH_DOMAIN,
      clientId: process.env.AUTH_CLIENT,
      authorizationParams: {
        redirect_uri: process.env.AUTH_REDIRECT
      }
    });
    auth0.loginWithRedirect();
  };

  const loginWithBureau = async() => {
    try {
      const success = await httpRequest('post', `${process.env.B_AUTH}/attempt`, {
        email,
        password
      });
      if (success.token) {
        if (process.env.NODE_ENV === 'development') {
          cookies.set('token', success.token, {
            path: '/',
            sameSite: 'none',
            secure: true // Ensures cookie is sent over HTTPS
          });
        }
        const platform = cookies.get('platform');
        if (platform === 'mobile') {
          try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).webkit.messageHandlers.userCredentialsCallbackHandler.postMessage(JSON.stringify({ email, password }));
          } catch (err) {
            // onError
          }
        }
        navigate('/masquerading');
      }
    } catch (e) {
      setLoading(false);
      setGenericError(e.errors[0].message);
    }
    setLoginAttempts(prev => prev + 1);
  };

  const setCaptchaExpired = () => {
    captchaRef.current.reset();
    setRecaptcha('');
  };

  useEffect(() => {
    const pattern = RegExp(emailRegex);
    const valid = pattern.test(email);
    if (valid) {
      setEmailError('');
    } else {
      setEmailError(t('Please enter a valid email'));
    }
  }, [email]);

  useEffect(() => {
    const valid = password !== '';
    if (valid) {
      setPasswordError('');
    } else {
      setPasswordError(t('You must enter a password'));
    }
  }, [password]);

  useEffect(() => {
    const currentPath = location.pathname || '';
    const currentSearch = location.search || '';
    
    cookies.set('redirect-url', `${currentPath}${currentSearch}`, {
      path: '/',
      sameSite: 'none',
      secure: true // Ensures cookie is sent over HTTPS
    });
    const usingA0 = cookies.get('use-a0');
    if (usingA0) {
      setUsingAuth0(true);
    }
  }, [cookies, location]);

  useEffect(() => {
    setDisabled(
      !usingAuth0 &&
      (emailError !== '' || passwordError !== '') ||
      (loginAttempts >= 5 && !recaptcha)
    );
  }, [usingAuth0, emailError, passwordError, loginAttempts, recaptcha]);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LogoContainerLayout loading={loading}>
      <form
        onSubmit={login}
      >
        <CustomInput
          label={t('Email')}
          value={email}
          setValue={setEmail}
          error={emailError}
        />

        <div className={styles.passwordWrapper}>
          <CustomInput
            label={t('Password')}
            value={password}
            setValue={setPassword}
            type={showPassword ? 'text' : 'password'}
            error={passwordError}
          />

          <button
            aria-label={showPassword ? t('Hide password') : t('Show password')}
            className={styles.showPassword}
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <IconHide /> : <IconShow />}
          </button>
        </div>

        {/* <Link to="/reset-password">{t('Forgot password?')}</Link> */}
        <RememberMeCheckbox
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
        />
        {loginAttempts >= 5 &&
          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey="6LdYtEAlAAAAALYG0IFkaPKW0NGADPOPnGAEwNud"
              onChange={setRecaptcha}
              onExpired={setCaptchaExpired}
            />
          </div>
        }
        { genericError ?
          (
            <div className={styles.errorMessage}>
              { genericError }
            </div>
          ) : null
        }
        <div className={styles.submitBtn}>
          <CustomButton
            label={t('Login')}
            type="submit"
            disabled={disabled}
          />
        </div>
      </form>
    </LogoContainerLayout>
  );
}
