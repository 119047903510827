// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CUC4l\\+j\\+HPnm7aKecYA6RQ\\=\\= {\n  width: 300px;\n  margin: 0 auto;\n  padding: var(--spacing-2);\n}\n\n.lR6dwobo3w42kOND1MrRiA\\=\\= {\n  width: 520px;\n  max-width: 95%;\n  margin: 0 auto;\n  background-color: var(--colour-panel-action);\n  padding: var(--spacing-3);\n  box-shadow: 0 0 8px rgba(0,0,0,.3);\n  border-radius: var(--spacing-1);\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,cAAc;EACd,4CAA4C;EAC5C,yBAAyB;EACzB,kCAAkC;EAClC,+BAA+B;AACjC","sourcesContent":[".logo {\n  width: 300px;\n  margin: 0 auto;\n  padding: var(--spacing-2);\n}\n\n.mainContentCard {\n  width: 520px;\n  max-width: 95%;\n  margin: 0 auto;\n  background-color: var(--colour-panel-action);\n  padding: var(--spacing-3);\n  box-shadow: 0 0 8px rgba(0,0,0,.3);\n  border-radius: var(--spacing-1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "CUC4l+j+HPnm7aKecYA6RQ==",
	"mainContentCard": "lR6dwobo3w42kOND1MrRiA=="
};
export default ___CSS_LOADER_EXPORT___;
